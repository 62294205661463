<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <div>
                <el-form :inline="true" class="demo-form-inline">
                    <el-form-item label="订单编号:">
                        <el-input class="s-input" v-model="queryParams.ordersn" placeholder="请输入订单编号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="审核结果:">
                        <el-select v-model="queryParams.state" placeholder="请选择审核结果" clearable @change="changeQuery">
                            <el-option label="驳回" :value="3"></el-option>
                            <el-option label="通过" :value="2"></el-option>
                            <el-option label="待审核" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="提现方式:">
                        <el-select v-model="queryParams.ty" placeholder="请选择提现方式" clearable @change="changeQuery">
                            <el-option label="银行卡" :value="3"></el-option>
                            <el-option label="支付宝" :value="2"></el-option>
                            <el-option label="微信" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <div style="float: right">
                        <el-button type="primary" @click="changeQuery()">搜索</el-button>
                    </div>
                </el-form>
            </div>
            <div class="dataList">
                <el-table :data="pageInfo.list" style="margin-top: 20px; width: 100%;" v-loading="loading" element-loading-text="数据加载中" stripe border ref="multipleTable">
                    <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                    <el-table-column prop="username" label="提现人" width="120" align="center"></el-table-column>
                    <el-table-column prop="ordersn" label="订单号" width="180" align="center"></el-table-column>
                    <el-table-column prop="number" label="提现账号" width="180" align="center"></el-table-column>
                    <el-table-column prop="price" label="提现金额" width="120" align="center"></el-table-column>
                    <el-table-column label="提现方式" prop="ty" align="center" width="120">
                        <template slot-scope="scope">
                            <el-tag type="primary" v-if="scope.row.ty === 1">微信</el-tag>
                            <el-tag type="primary" v-if="scope.row.ty === 2">支付宝</el-tag>
                            <el-tag type="primary" v-if="scope.row.ty === 3">银行卡</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="content" label="提现内容" align="center"></el-table-column>
                    <el-table-column prop="bank" label="开户行" width="120" align="center"></el-table-column>
                    <el-table-column prop="reason" label="备注" align="center"></el-table-column>
                    <el-table-column prop="user.remarks" label="用户备注" align="center"></el-table-column>

                    <!-- <el-table-column prop="remarks" v-else label="备注" align="center"></el-table-column> -->
                    <el-table-column label="审核状态" prop="state" align="center" width="120">
                        <template slot-scope="scope">
                            <el-tag type="info" v-if="scope.row.state === 1">待审核</el-tag>
                            <el-tag type="primary" v-if="scope.row.state === 2">通过</el-tag>
                            <el-tag type="primary" v-if="scope.row.state === 3">驳回</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" align="center" width="200" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" @click="detail(scope.$index, scope.row.uid)">用户详情</el-button>
                            <el-button type="text" icon="el-icon-edit" v-if="scope.row.state != 3" @click="edit(scope.$index, scope.row)">编辑</el-button>
                            <el-button type="text" @click="sync(scope.row.id)" v-if="scope.row.state==1" class="green">同步审核</el-button>
                            <el-button type="text" @click="state(scope.row,2)" v-if="scope.row.state == 1" class="green">通过</el-button>
                            <el-button type="text" @click="state(scope.row,3)" v-if="scope.row.state == 1" class="red">驳回</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-dialog title="编辑" :visible.sync="addVisible" width="600px" class="dialog_box">
                    <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                        <el-form-item label="提现账号" prop="number">
                            <el-input v-model="form.number" placeholder="请输入提现账号"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="提现金额" prop="price">
                            <el-input v-model="form.price" placeholder="请输入提现金额"></el-input>
                        </el-form-item> -->
                        <el-form-item label="开户行" prop="bank">
                            <el-input v-model="form.bank" placeholder="请输入开户行"></el-input>
                        </el-form-item>
                        <el-form-item label="提现内容" prop="content">
                            <el-input v-model="form.content" placeholder="请输入提现内容"></el-input>
                        </el-form-item>
                        <el-form-item label="提现备注" prop="remarks">
                            <el-input v-model="form.remarks" placeholder="请输入提现备注"></el-input>
                        </el-form-item>
                        <el-form-item label="提现原因" prop="reason">
                            <el-input v-model="form.reason" placeholder="请输入提现原因"></el-input>
                        </el-form-item>
                        <el-form-item label="提现方式" prop="ty">
                            <el-select v-model="form.ty" placeholder="请选择提现方式">
                                <el-option label="银行卡" :value="3"></el-option>
                                <el-option label="支付宝" :value="2"></el-option>
                                <el-option label="微信" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="审核状态" prop="state">
                            <el-radio-group v-model="form.state">
                                <el-radio :label="2">审核通过</el-radio>
                                <el-radio :label="1">待审</el-radio>
                                <el-radio :label="3">驳回</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="addVisible = false">取 消</el-button>
                        <el-button type="primary" @click="saveData">提 交</el-button>
                    </div>
                </el-dialog>
                <!-- 通过 驳回提示框 -->
                <el-dialog title="提示" :visible.sync="confirmVisible" width="400px" center v-if="confirmVisible">
                    <el-form v-if="ty===2" class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                        <el-form-item label="打款单号" prop="pay_number">
                            <el-input v-model="form.pay_number" placeholder="请输入打款单号"></el-input>
                        </el-form-item>
                    </el-form>
                    <el-form v-if="ty===1" class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                        <el-form-item label="驳回原因" prop="reason">
                            <el-input v-model="form.reason" placeholder="请输入驳回原因"></el-input>
                        </el-form-item>
                        <div v-if="ty===1" class="del-dialog-cnt">{{ confirmContent }}</div>

                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="confirmVisible = false">取 消</el-button>
                        <el-button type="primary" @click="stateData">确 定</el-button>
                    </span>
                </el-dialog>
                <!-- 分页 -->
                <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import Url from '@/components/Url';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            formData: {},
            form: {},
            id: 0,
            addVisible: false,
            confirmVisible: false,
            confirmContent: '',
            menuList: ['资金', '提现审核'],
            pageInfo: {},
            queryParams: {
                page: 1,
                pagesize: 10
            },
            ty: 1,
            pay_number: null,
            rules: {
                number: [{
                    required: true,
                    message: '提现账户不能为空',
                    trigger: 'blur'
                }, { message: '请输入数字值', pattern: /^[0-9]*[1-9][0-9]*$/ }],
                price: [{
                    required: true,
                    message: '提现金额不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                bank: [{
                    required: true,
                    message: '开户行不能为空',
                    trigger: 'blur'
                }],
                content: [{
                    required: true,
                    message: '内容不能为空',
                    trigger: 'blur'
                }],
                ty: [{
                    required: true,
                    message: '提现方式不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                pay_number: [{
                    required: true,
                    message: '打款单号不能为空',
                    trigger: 'blur'
                }, { message: '请输入数字值', pattern: /^[0-9]*[1-9][0-9]*$/ }],

            },
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        edit(index, row) {
            this.disabled = true
            this.title = '编辑';
            const item = this.pageInfo.list[index];
            let time = [item.stime, item.etime]
            //处理时间
            this.form = Object.assign({}, item);
            this.form.price = this.form.price + ''
            this.$set(this.form, 'time', time);
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        state(row, state) {
            if (state == 3) {
                this.ty = 1
                this.confirmContent = '确定是否驳回?';
            }
            else {
                this.ty = 2
                this.confirmContent = '确定是否通过?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state
            this.confirmVisible = true;
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = '/wallet/carry/edit';
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success('编辑成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        loadData() {
            this.loading = true;
            request.get('/wallet/carry/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list.map(function (val) {
                        val.pay_time = deal.timestamp(val.pay_time, 'unix');
                        val.pay_state = val.pay_state == 0 ? '支出' : '收入';
                        val.reason = val.reaso ? val.reason : val.remarks
                        return val;
                    })

                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 确定下线
        stateData() {
            if (this.ty === 1) {
                request.post('/wallet/carry/edit', this.form).then(ret => {
                    if (ret.code == 1) {
                        this.$message.success('操作成功');
                        this.loadData();
                        this.confirmVisible = false;
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            } else {
                if (this.$refs.form) {
                    console.log(555, this.form)
                    this.$refs.form.validate(valid => {
                        console.log(11111, this.form)
                        if (valid) {
                            request.post('/wallet/carry/edit', this.form).then(ret => {
                                if (ret.code == 1) {
                                    this.$message.success('操作成功');
                                    this.loadData();
                                    this.confirmVisible = false;
                                } else {
                                    this.$message.error(ret.msg);
                                }
                            });
                        }
                        else {
                            this.$message.error('请补全信息');
                            return false;
                        }
                    });
                };
            }

        },
        // 同步审核
        sync(id) {
            request.post('/wallet/carry/syn', { id: id }).then(ret => {
                if (ret.code == 1) {
                    this.isSync = true
                    this.$message.success('同步成功')
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        detail(index, id) {
            this.$router.push({ path: '/custom-Detail', query: { id: id } })
        }
    }
};
</script>

<style  scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>